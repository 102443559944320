import React from 'react'
import {Link} from 'react-router-dom';
import './Footer.css';
import headerlogo from '../img/360FileSyncLogo.svg';
// import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

function footer() {
  return (
    <div className='bg-dark pt-4'>
       <div className='bg-dark container'>
       <Link className="navbar-brand" to="/">
        <img alt='hello' src={headerlogo}/>
        </Link>
        <div className='row justify-content-start'>
              <div className="footer-logo col-md-4 col-sm-12 col-lg-4 py-3">
                  <div className="text-white footer_icon">
                      <p>
                        <i className="bi bi-telephone-fill me-2 footer_icon"></i>
                        <Link className='clr_white' to="tel:+1 323 641 4417" aria-label="USA : +1 323 641 4417" >USA : +1 323 641 4417</Link>
                      </p>
                      <p className='pb-3'>
                          <i className="bi bi-geo-alt-fill me-2 footer_icon"></i>
                          Address : 1968 S. Coast Hwy #1412,<br/>
                          <span> Laguna Beach CA 92651</span>
                      </p>
                      <p>
                        <i className="bi bi-telephone-fill me-2 footer_icon"></i>
                        <Link className='clr_white' to="tel:+44 740 327 9473" aria-label="UK : +44 740 327 9473">UK : +44 740 327 9473</Link>
                      </p>
                      <p>
                      <i className="bi bi-geo-alt-fill me-2 footer_icon"></i>
                          Address : Plaza Suite 8, KD Tower, Cotterells,<br/> 
                          <span >Hemel Hempstead, Herts, HP1 1FW, United Kingdom</span>
                      </p>
                  </div>
                  <div className="footer-social">
                      <div className="social-icon-items">
                          <Link to="https://www.facebook.com/360SMSApp/" aria-label="facebook link">
                              <span className="social-icons mxr-1"><i className="fab fa-facebook-f fa-2x social-icon facebook"></i></span>
                          </Link>
                          <Link to="https://twitter.com/360smsapp" aria-label="twitter link">
                              <span className="social-icons mxr-1"><i className="fab fa-twitter fa-2x social-icon"></i></span>
                          </Link>
                          <Link to="https://www.linkedin.com/company/360smsapp/" aria-label="linkedIn link">
                              <span className="social-icons mxr-1"><i className="fab fa-linkedin-in fa-2x social-icon"></i></span>
                          </Link>
                          <Link to="https://www.instagram.com/360smsapp/?hl=en" aria-label="Instagram link" target="_blank">
                          <span className="social-icons mxr-1"><i className="fab fa-instagram fa-2x social-icon"></i></span>
                          </Link>
                  </div>

              </div>
              </div>
          <div className="col-md-4 col-sm-12 col-lg-4">
          <ul className="bottom_listing">
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/success-stories/" target="_blank">Success Stories</Link></li>
              {/* <li className='pb-2'><Link className='clr_white' to=" https://360smsapp.com/salesforce-chatbot/" target="_blank">Salesforce Chatbot</Link></li> */}
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/sms/" target="_blank">Salesforce SMS</Link></li>
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/whatsapp/" target="_blank">Whatsapp Salesforce Integration</Link></li>
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/salesforce-texting/" target="_blank">Text marketing for Salesforce</Link></li>
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/computer-telephony-integration/" target="_blank">CTI salesforce integration</Link></li>
          </ul>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4">
          <ul className="bottom_listing">
              <li className='pb-2'>Globally Local</li>
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/aus/" target="_blank">Australia</Link></li>
              <li className='pb-2'><Link className='clr_white' to="https://360smsapp.com/uk/" target="_blank">United Kingdom</Link></li>
              {/* <li className='pb-2'>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label text-white">GET IN TOUCH</label>
                  <span className='d-flex'>
                    <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
                    <button type="button" className="btn btn-outline-info">Submit</button>
                  </span>
                </div>
              </li>
              <li className='pb-2'><button type="button" className="btn btn-outline-info">Salesforce Consulting Services</button></li> */}
          </ul>
          </div>
        </div>
        <div className='pt-4 pb-3 text-white text-center border-top border-light-subtle'>
        © 360 Degree Cloud. All Right Reserved | <Link to={"/privacy-policies"} target='_blank' className='link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Privacy Policy</Link> | <Link to={"/terms-and-conditions"} className='link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' target='_blank'>Terms & Conditions</Link> 
        </div>
      </div>
    </div>
  )
}

export default footer